import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import MetaImage from '../assets/images/about-us-meta.png'
import Block from '../components/Block'
import Layout from '../components/Layout'
import Page from '../components/Page'
import TeamMember from '../components/TeamMember'
import { photoFor } from '../imageUtils'

const members = [
  {
    name: 'Otto Hilska',
    title: 'Founder & CEO',
    bio: 'Past: Chief Product Officer at Smartly.io, founder of Flowdock',
    photo: 'otto.jpg',
    twitter: 'https://twitter.com/mutru',
    linkedin: 'https://www.linkedin.com/in/hilska',
  },
  {
    name: 'Mika Vakula',
    title: 'Software Engineer, Team Lead',
    bio: 'Past: CTO at Tocoman, Senior Consultant at Reaktor, CEO at aTalent Recruiting',
    linkedin: 'https://www.linkedin.com/in/mikavaisanen',
    photo: 'mika.jpg',
  },
  {
    name: 'Oskari Virtaoja',
    title: 'Head of Engineering',
    bio: 'Past: Dev Team Lead, Software Engineer at Smartly.io',
    photo: 'oskari.jpg',
    linkedin: 'https://www.linkedin.com/in/oskarivirtanen',
  },
  {
    name: 'Roman Musatkin',
    title: 'Head of Product & Design',
    bio: 'Past: Product Design Lead at Smartly.io',
    photo: 'roman.jpg',
    linkedin: 'https://www.linkedin.com/in/musatkin',
  },
  {
    name: 'Jarno Rantanen',
    title: 'Software Engineer, Team Lead',
    bio: 'Past: Tech Principal, Senior Consultant at Futurice',
    linkedin: 'https://www.linkedin.com/in/jarnorantanen',
    photo: 'jarno.jpg',
  },
  {
    name: 'Eero Kettunen',
    title: 'Customer Success Manager',
    bio: 'Past:  Consultant at BCG',
    linkedin: 'https://www.linkedin.com/in/eero-kettunen-131b8688/',
    photo: 'eero.jpg',
  },
  {
    name: 'Aleksi Heinonen',
    title: 'Head of Finance & Operations',
    bio: 'Past: Controller at Certego, Vice President, Head of Finance at Junction',
    linkedin: 'https://www.linkedin.com/in/aleksiheinonen/',
    photo: 'aleksi.jpg',
  },
  {
    name: 'Lauri Ikonen',
    title: 'Product Manager',
    bio: 'Past: Group Product Manager, Product Manager at Smartly.io',
    linkedin: 'https://www.linkedin.com/in/ikonenlauri/',
    photo: 'lauri.jpg',
  },
  {
    name: 'Kimmo Brunfeldt',
    title: 'Software Engineer',
    bio: 'Past: Senior Software Engineer at Aiven, Lead Developer at Futurice',
    linkedin: 'https://www.linkedin.com/in/kimmobrunfeldt/',
    photo: 'kimmo.jpg',
  },
  {
    name: 'Ari-Pekka Koponen',
    title: 'Head of Platform',
    bio: 'Past: Frontend Lead at Supermetrics, CTO Team at Vincit',
    linkedin: 'https://www.linkedin.com/in/apkoponen/',
    twitter: 'https://twitter.com/apkoponen',
    photo: 'ari-pekka.jpg',
  },
  {
    name: 'Petri Avikainen',
    title: 'Software Engineer',
    bio: 'Past: VP Engineering, Software Engineer at Kaiku Health',
    linkedin: 'https://www.linkedin.com/in/pmavik',
    photo: 'petri.jpg',
  },
  {
    name: 'Eemeli Kantola',
    title: 'Software Engineer',
    bio: 'Past: Senior Software Developer, Lean/Agile Consultant at Futurice',
    linkedin: 'https://www.linkedin.com/in/eemeli-kantola',
    photo: 'eemeli.jpg',
  },
  {
    name: 'Andy Carlson',
    title: 'Head of Enterprise Sales',
    bio: 'Past: Scaled Agile Inc, Rally Software, Thoughtworks',
    linkedin: 'https://www.linkedin.com/in/andrewdanacarlson/',
    twitter: 'https://twitter.com/carlsonandy',
    photo: 'andy.jpg',
  },
  {
    name: 'Pinja Dodik',
    title: 'Head of Marketing',
    bio: 'Past: Demand Gen Lead at Supermetrics',
    linkedin: 'https://www.linkedin.com/in/pinjadodik/',
    twitter: 'https://twitter.com/pinjaerika',
    photo: 'pinja.jpg',
  },
  {
    name: 'Hugo Kiiski',
    title: 'Software Engineer',
    bio: 'Past: Staff Engineer at Smartly.io',
    linkedin: 'https://www.linkedin.com/in/hugokiiski/',
    photo: 'hugo.jpg',
  },
  {
    name: 'Miikka Jokela',
    title: 'Growth Marketing Manager',
    bio: 'Past: Growth Strategist at Vainu',
    linkedin: 'https://www.linkedin.com/in/miikkajokela/',
    photo: 'miikka.jpg',
  },
  {
    name: 'Sayo Oladeji',
    title: 'Software Engineer, Team Lead',
    bio: 'Past: Meta, Smartly.io',
    linkedin: 'https://www.linkedin.com/in/oluwasayo/',
    photo: 'sayo.jpg',
  },
  {
    name: 'Oskari Kallio',
    title: 'Lead Brand Designer',
    bio: 'Past: Lead Visual Designer at Kaiku Health',
    linkedin: 'https://www.linkedin.com/in/oskari-kallio-13940a19a/',
    photo: 'oskarik.jpg',
  },
  {
    name: 'Rebecca Murphey',
    title: 'Field CTO',
    bio: 'Past: Engineering effectiveness at Stripe, Indeed',
    linkedin: 'https://www.linkedin.com/in/rmurphey/',
    photo: 'rebecca.png',
  },
  {
    name: 'Kimmo Puputti',
    title: 'Software Engineer',
    bio: 'Past: Staff Engineer at Swappie, Lead Web Engineer at Sharetribe',
    linkedin: 'https://www.linkedin.com/in/kimmopuputti/',
    photo: 'kimmo-puputti.jpg',
  },
  {
    name: 'Akseli Aho',
    title: 'Customer Success Manager',
    bio: 'Past: CEO at Junction, Consultant at Accenture',
    linkedin: 'https://www.linkedin.com/in/akseliaho/',
    photo: 'akseli.jpeg',
  },
  {
    name: 'Avik Dey',
    title: 'Product Designer',
    bio: 'Past: Design Systems at Smartly.io',
    photo: 'avik.jpg',
    linkedin: 'https://www.linkedin.com/in/deyavik',
  },
  {
    name: 'Alexi Ortega',
    title: 'Customer Success Manager',
    bio: 'Past: Technical Account Manager at VMware',
    photo: 'alexi.jpg',
    linkedin: 'https://www.linkedin.com/in/alexi-ortega/',
  },
  {
    name: 'Niko Salmela',
    title: 'Software Engineer',
    bio: 'Past: Senior Backend Engineer at Wolt, Senior Software Engineer at Swappie',
    photo: 'niko.jpg',
    linkedin: 'https://www.linkedin.com/in/niko-salmela-041983a3/',
  },
  {
    name: 'Julien Chakra',
    title: 'Software Engineer',
    bio: 'Past: Software Engineer at Futurice, Software Engineer at Unity Technologies',
    photo: 'julien.jpg',
    linkedin: 'https://www.linkedin.com/in/julien-chakra-33793029/',
  },
  {
    name: 'Miikka Holkeri',
    title: 'Product Manager',
    bio: 'Past: Head of Product at MinnaLearn, Product Manager at Smartly.io',
    linkedin: 'https://www.linkedin.com/in/holkeri/',
    photo: 'miikka-holkeri.jpeg',
  },
  {
    name: 'Nathan Dao',
    title: 'Software Engineer',
    bio: 'Past: Software Engineer at Futurice',
    photo: 'nathan.jpeg',
    linkedin: 'https://www.linkedin.com/in/nathandao/',
  },
  {
    name: 'Liv Læssøe',
    title: 'Product Designer',
    bio: 'Past: Lead Product Designer at MinnaLearn, Lead Product Designer at Starship Technologies',
    linkedin: 'https://www.linkedin.com/in/lilas/',
    photo: 'liv.png',
  },
  {
    name: 'Mikko Porkola',
    title: 'Software Engineer',
    bio: 'Past: Engineering Team Lead and Staff Engineer at Wolt',
    photo: 'mikko.jpeg',
    linkedin: 'https://www.linkedin.com/in/mikkoporkola/',
  },
  {
    name: 'Dima Egorov',
    title: 'Software Engineer',
    bio: 'Past: Software Engineer at HealthSamurai',
    photo: 'dima.png',
    linkedin: 'https://www.linkedin.com/in/persistent/',
  },
  {
    name: 'Eero Tuhka',
    title: 'Software Engineer',
    bio: 'Past: VP Data at Upright, PM and Data Scientist at Smartly',
    photo: 'eero-tuhka.jpg',
    linkedin: 'https://www.linkedin.com/in/eero-tuhka/',
  },
  {
    name: 'Kaarlo Kock',
    title: 'Software Engineer',
    bio: 'Past: Software Engineer at Reaktor',
    photo: 'kaarlo.jpg',
    linkedin: 'https://www.linkedin.com/in/kaarlo-kock-161771269/',
  },
  {
    name: 'Jani Niemelä',
    title: 'Software Engineer',
    bio: 'Past: Senior Backend Engineer at Wolt, Senior Software Engineer at Swappie',
    photo: 'jani.jpg',
    linkedin: 'https://www.linkedin.com/in/siquel/',
  },
  {
    name: 'Santeri Toivanen',
    title: 'Marketing Manager',
    bio: 'Past: Marketing at Focal, CMO at Slush',
    photo: 'santeri.jpeg',
    linkedin: 'https://www.linkedin.com/in/santeritoivanen/',
  },
  {
    name: 'First Sutham',
    title: 'Software Engineer',
    bio: 'Past: Unity Technologies, Thoughtworks',
    photo: 'first.jpg',
    twitter: 'https://twitter.com/heyfirst_',
    linkedin: 'https://www.linkedin.com/in/first-sutham',
  },
  {
    name: 'Francesco Perri',
    title: 'Account Executive',
    bio: 'Past: Pluralsight Skills and Flow',
    linkedin: 'https://www.linkedin.com/in/fnper',
    photo: 'francesco.jpg',
  },
  {
    name: 'Juhana Nurmio',
    title: 'Product Manager',
    bio: 'Past: Product Director, Smartly.io',
    photo: 'juhana.jpg',
    linkedin: 'https://www.linkedin.com/in/juhananurmio/',
  },
  {
    name: 'Bill Rufo',
    title: 'VP of Sales & Customer Success',
    bio: 'Past: CRO at Upperedge',
    photo: 'bill.jpg',
    linkedin: 'https://www.linkedin.com/in/billrufo/',
  },
  {
    name: 'Ville Harvala',
    title: 'Software Engineer',
    bio: 'Past: Software Engineer at Noice',
    linkedin: 'https://www.linkedin.com/in/ville-harvala/',
    photo: 'ville.jpg',
  },
  {
    name: 'Jess Wolfe',
    title: 'Customer Success Manager',
    bio: 'Past: Solution Architect and Product Manager at Atlassian',
    linkedin: 'https://www.linkedin.com/in/thejessicawolfe/',
    photo: 'jess.png',
  },
]

const AboutUsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      justifyCenter
      title="About us"
      description="Swarmia was founded to empower every software team in the world to do their best work. Read more about the Swarmia team and its members here."
      metaImage={MetaImage}
    >
      <Page>
        <TeamContainer>
          <Block size="small">
            <h1>About us</h1>
            <p>
              We’ve been part of engineering organizations that work extremely
              well together, deliver high-quality work, and ship often &hellip;
              and organizations that don’t. The best organizations have had a
              few things in common: visibility into their own ways of working, a
              culture of small but continuous improvement, and great tooling to
              help them improve in a way that sticks.
            </p>
            <p>
              <i>Swarmia</i> was founded to help every team achieve that
              visibility, culture of continuous improvement, and tooling to make
              it happen.
            </p>
            <p>
              We believe in the power of transparency, to help you make the
              right decisions at the right time. We believe in the compounding
              effect of positive feedback loops over a longer timeframe. And we
              believe in the engineering team as the fundamental unit to
              optimize, improve and nurture.
            </p>
            <p>
              More than anything, as a software engineering team ourselves,
              we’re building the tool we believe will enable every team to do
              their best work.
            </p>
            <h2 id="team">
              Team
              <a href="/careers" className="hiring" rel="noreferrer">
                We&lsquo;re hiring!
              </a>
            </h2>
            <p>
              Our team has successfully delivered software, and led engineering
              & design teams in high-growth, fast-moving environments.
            </p>
          </Block>
          <Block>
            <div className="grid-row">
              {members.map((member, idx) => (
                <TeamMember
                  {...member}
                  photo={photoFor(data, member.photo)}
                  key={`${idx}-${member.name}`}
                />
              ))}
            </div>
          </Block>
        </TeamContainer>
      </Page>
    </Layout>
  )
}

const query = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "/images/team//" } }) {
      edges {
        node {
          absolutePath
          childImageSharp {
            gatsbyImageData(
              width: 120
              height: 120
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export default AboutUsPage

const TeamContainer = styled.div`
  .hiring {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    letter-spacing: -0.02rem;
    background: #4a3ecf;
    border-radius: 30px;
    padding: 4px 8px;
    margin-left: 12px;
    position: relative;
    top: -2px;
    transition: background 0.3s linear;

    &:hover {
      background: #372f99;
    }
  }

  .grid-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 48px;

    .grid-item {
      flex-basis: 25%;
      -ms-flex: auto;
      width: 240px;
      position: relative;
      padding-bottom: 48px;
      box-sizing: border-box;
    }

    .photo {
      width: 120px;
      height: 120px;
      background: #dfe1ed;
      border-radius: 12px;
      overflow: hidden;
    }

    .grid-row a {
      text-decoration: none;
    }

    .wrapping-link {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    .grid-item-wrapper {
      -webkit-box-sizing: initial;
      -moz-box-sizing: initial;
      box-sizing: initial;
      margin: 0;
      height: 100%;
      width: 100%;
      position: relative;
    }

    .grid-item-container {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .grid-image-top {
      height: 120px;
      width: 120px;
      background-size: cover;
      position: relative;
      background-position: 50% 50%;
    }

    .grid-image-top .centered {
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      height: 120px;
      width: 120px;
      background-position: center;
    }

    .grid-item-content {
      padding: 20px 40px 0 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .item-name {
      font-weight: 600;
    }

    .item-name,
    .item-title {
      display: block;
    }

    .item-title {
      margin-bottom: 12px;
      color: #5a5f70;
    }

    .item-bio {
      color: #5a5f70;
      margin-bottom: 12px;
      display: block;
      font-size: 16px;
      line-height: 20px;
    }

    .item-linkedin,
    .item-twitter {
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      margin-right: 8px;
      margin-left: -4px;
      border-radius: 12px;

      img {
        width: 12px;
        height: 12px;
      }

      &:hover {
        background: #f3f4f7;
      }
    }

    @media (max-width: 815px) {
      .grid-item {
        flex-basis: 50%;
      }
    }
    @media (max-width: 600px) {
      .grid-item {
        flex-basis: 100%;
      }
    }
  }
`
