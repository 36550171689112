import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import MetaImage from '../assets/images/books/build-cover.jpg'
import { Chapter, buildBookChapters } from '../bookUtils'
import Block from '../components/Block'
import { BlogCta } from '../components/BlogCta'
import { BookToc } from '../components/BookToc'
import Layout from '../components/Layout'
import { MenuToggle } from '../components/MenuToggle'
import SidebarLayout from '../components/SidebarLayout'
import { TableOfContents } from '../components/TableOfContents'
import YoutubeVideo from '../components/YoutubeVideo'
import { InlineQuote } from '../components/blocks/InlineQuote'
import BuildBookTitle from '../components/book/BuildBookTitle'
import Box from '../components/primitives/Box'
import Button from '../components/system/Button'
import {
  useStoreBuildBookLocation,
  useTrackCurrentHeading,
} from '../navigationUtils'
import theme from '../theme'
import { Heading, parseGraphQLHeadings } from '../tocUtils'

const topPadding = 32

export default function BookTemplate({ data, pageContext }) {
  const { body } = data.mdx

  const { currentHeadingId } = useTrackCurrentHeading(pageContext.slug)
  useStoreBuildBookLocation(currentHeadingId)
  const { currentHeadingId: currentH2HeadingId } = useTrackCurrentHeading(
    pageContext.slug,
    'h2',
  )

  const chapterTitle = data.mdx.headings[0].value
  const url = 'https://www.swarmia.com' + data.mdx.path
  const title = chapterTitle + ' | Build | Swarmia'
  const description = ''

  const [headings] = useState(() =>
    parseGraphQLHeadings(
      data.mdx.headings
        .filter(h => h.depth === 2)
        .map(h => ({ ...h, depth: 0 })),
    ),
  )

  const content = (
    <>
      <Box className="prose use-small-outset" paddingY={64}>
        <MDXProvider
          components={{
            // NOTE: If we start having many components here, implement lazy loading to keep things fast
            // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
            BlogCta,
            blockquote: props => <InlineQuote quote={props.children} />,
            YoutubeVideo: props => <YoutubeVideo borderRadius={0} {...props} />,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </>
  )

  const footer = (
    <Box>
      <Box display="flex" justifyContent="space-between" paddingBottom={48}>
        {pageContext.previousPath ? (
          <Button variant="secondary" href={pageContext.previousPath}>
            ← Previous chapter
          </Button>
        ) : (
          <div />
        )}
        {pageContext.nextPath ? (
          <Button variant="secondary" href={pageContext.nextPath}>
            Next chapter →
          </Button>
        ) : (
          <div />
        )}
      </Box>
      <Box padding={24} font="small" color="black600" textAlign="center">
        Copyright © 2024 Swarmia. All rights reserved.
      </Box>
    </Box>
  )

  return (
    <Layout justifyCenter hideNavigation metaImage={MetaImage}>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="author" content="Rebecca Murphey" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={chapterTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@rmurphey" />
        <meta name="twitter:title" content={chapterTitle} />
        <meta name="twitter:description" content={description} />
      </Helmet>

      <Block size="large">
        <Box marginX="auto">
          <SidebarLayout
            sidebar={
              <StickySidebar
                headings={headings}
                title={chapterTitle}
                titleId=""
                chapters={buildBookChapters(data.chapters)}
                currentHeadingId={currentH2HeadingId}
              />
            }
            main={content}
          />
          <SidebarLayout sidebar={<div />} main={footer} />
        </Box>
      </Block>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      wordCount {
        words
      }
      headings {
        value
        depth
      }
    }
    chapters: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/books/build/" } }
      sort: { order: ASC, fields: [slug] }
      limit: 1000
    ) {
      edges {
        node {
          id
          slug
          headings {
            value
            depth
          }
        }
      }
    }
  }
`

function StickySidebar({
  headings,
  title,
  titleId,
  chapters,
  currentHeadingId,
}: {
  headings: Heading[] | null
  title: string
  titleId: string
  chapters: Chapter[]
  currentHeadingId: string
}) {
  const { ref, inView } = useInView({
    rootMargin: `0px 0px 0px 0px`,
    fallbackInView: true,
    initialInView: true,
  })
  const [showFullToc, setShowFullToc] = useState(false)

  const toggleMenu = () => {
    setShowFullToc(!showFullToc)
  }

  useEffect(() => {
    document.body.style.overflow = showFullToc ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [showFullToc])

  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} />
      <SidebarContent isDimmed={!showFullToc && !inView} isFixed={showFullToc}>
        <Button
          onClick={toggleMenu}
          variant="ghost"
          size="large"
          marginTop={8}
          paddingX={12}
        >
          <MenuToggle active={showFullToc} />
          <Box fontSize={24} paddingLeft={10} paddingTop={4}>
            Build
          </Box>
        </Button>
        {showFullToc ? (
          <Box paddingTop={16} paddingLeft={12} paddingBottom={48}>
            <Button
              href="/"
              variant="ghost"
              size="medium"
              paddingX={12}
              css="margin-left: -12px;"
              marginBottom={32}
            >
              ← &nbsp; Swarmia.com
            </Button>

            <Box paddingBottom={48}>
              <BuildBookTitle />
            </Box>
            <BookToc
              chapters={chapters}
              onLinkClick={() => setShowFullToc(false)}
            />
          </Box>
        ) : (
          <Box paddingTop={32} className="toc-wrapper">
            {headings ? (
              <TableOfContents
                title={title}
                titleId={titleId}
                headings={headings}
                currentHeadingId={currentHeadingId}
              />
            ) : null}
          </Box>
        )}
      </SidebarContent>
    </div>
  )
}

const SidebarContent = styled.div<{ isDimmed: boolean; isFixed: boolean }>`
  position: ${props => (props.isFixed ? 'fixed' : 'sticky')};
  top: 0;
  z-index: 10;
  padding-top: ${topPadding}px;

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    .toc-wrapper {
      display: none;
    }
  }

  ${props =>
    props.isDimmed
      ? `
  opacity: 0.25;
  transition: opacity 0.15s ease;
  &:hover {
    opacity: 1;
  }`
      : ``}

  ${props =>
    props.isFixed
      ? `
      background-color: #fff;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      `
      : `
      max-height: calc(100vh - 40px);
      `}
`
