import { useLocation } from '@gatsbyjs/reach-router'
import { MDXProvider } from '@mdx-js/react'
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { BlogCta } from './BlogCta'
import { Video } from './Video'
import YoutubeVideo from './YoutubeVideo'
import { InlineQuote } from './blocks/InlineQuote'
import { CoverImage } from './blog/CoverImage'
import Box from './primitives/Box'
import Button from './system/Button'

type QueryImage = IGatsbyImageData & {
  original: {
    width: number
    src: string
  }
}

interface Entry {
  body: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  frontmatter: {
    title: string
    date: string
    slug: string
    meta: {
      imageAlt?: string
      image: { w640: QueryImage; w1000: QueryImage }
    }
  }
}

const ChangelogEntry = ({
  entry: { body, frontmatter },
  standalone,
}: {
  entry: Entry
  standalone?: boolean
}) => {
  const { title, date, meta } = frontmatter
  const imageVariant =
    frontmatter.meta.image?.w640.original.width >= 2000 ? 'w1000' : 'w640'
  const cover = getImage(frontmatter.meta.image?.[imageVariant])
  const pathname = useLocation().pathname

  function showChangelogLabel() {
    if (pathname != '/changelog/') {
      return (
        <>
          <Button
            href="/changelog/"
            variant="link"
            font="blogLarge"
            color="black400"
          >
            Changelog
          </Button>{' '}
          ·{' '}
        </>
      )
    }
    return null
  }

  const heading = standalone ? (
    <Box.h1 font="blogH1" paddingBottom={48}>
      {title}
    </Box.h1>
  ) : (
    <Box.h2 font="blogH2" paddingBottom={32}>
      {title}
    </Box.h2>
  )
  return (
    <Box>
      <Box font="blogLarge" color="black400" paddingBottom={16}>
        {showChangelogLabel()}
        {date}
      </Box>
      {!standalone ? <a href={frontmatter.slug}>{heading}</a> : heading}
      <Box display="flex" justifyContent="center" paddingBottom={64}>
        {cover ? (
          <a
            href={meta.image.w640.original.src}
            target="_blank"
            rel="noreferrer"
            style={{ all: 'unset', cursor: 'pointer' }}
          >
            <CoverImage
              image={cover}
              alt={meta.imageAlt}
              bleed={imageVariant === 'w1000'}
            />
          </a>
        ) : null}
      </Box>
      <Box className="prose">
        <MDXProvider
          components={{
            // NOTE: If we start having many components here, implement lazy loading to keep things fast
            // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
            BlogCta,
            blockquote: props => <InlineQuote quote={props.children} />,
            Video,
            YoutubeVideo: props => (
              <YoutubeVideo borderRadius={16} {...props} />
            ),
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </Box>
  )
}

export default ChangelogEntry
