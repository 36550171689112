import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import IllustrationBlock from '../components/blocks/IllustrationBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../components/blocks/QuoteBlock'
import QuoteMosaicBlock from '../components/blocks/QuoteMosaicBlock'
import ToolsBlock from '../components/blocks/ToolsBlock'
import VideoBlock from '../components/blocks/VideoBlock'
import Box from '../components/primitives/Box'
import Blurb from '../components/system/Blurb'
import GlobalStyleReset from '../components/system/GlobalStyleReset'
import { responsiveScale } from '../styles/helpers'
import MetaImage from '../assets/images/meta/developer-experience-platform.png'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-experience/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/overview/product-overview-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    workingAgreements: file(
      relativePath: {
        eq: "images/product/developer-experience/working-agreements.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/metrics-380.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/initiatives-430.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    lead: file(relativePath: { eq: "images/lead/surveys-and-metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
    slack: file(
      relativePath: { eq: "images/product/developer-experience/slack.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    cicd: file(relativePath: { eq: "images/github-slack/ci-insights.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    cicdsmall: file(
      relativePath: { eq: "images/product/overview/ci-checks.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    dora: file(relativePath: { eq: "images/blurbs/dora-670.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    space: file(
      relativePath: { eq: "images/product/overview/investment-balance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    investmentbalance: file(
      relativePath: { eq: "images/product/investment-balance/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicRight3: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/initiatives-430.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    surveys2: file(
      relativePath: { eq: "images/product/developer-experience/surveys.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
  }
`

const ExperiencePlatformPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Developer experience platform"
      variant="dark"
      isNew
      description="Give your engineering organization the metrics and tools they need to ship high-quality software faster."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          title="The all-in-one developer experience platform"
          content={
            <>
              Give your engineering organization the metrics and tools they need
              to ship high-quality software faster.
            </>
          }
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <DarkLogoBlock />
        <Box>
          <LeadBlock
            heading={
              <>
                Boost developer productivity and experience with holistic data
              </>
            }
            content="Swarmia combines system metrics and developer feedback so you know exactly what to improve and how."
          />
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.lead)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="dave" />
        <LeadBlock
          heading="Track the right things at every level of the organization"
          content="Whether you’re a CTO, a software engineer, or anything in between, Swarmia gives you the insights you need to make better decisions."
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.codeoverview)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteMosaicBlock
          title="Remove process blockers and improve delivery"
          content={
            <>
              Swarmia turns GitHub and issue tracker data into actionable views
              that help teams quickly identify where the bottlenecks are.
              <br />
              <br />
              The real-time metrics let you track developer productivity on an
              organizational level, or dig deeper into team-level work.{' '}
            </>
          }
          image={getImage(data.mosaicRight)!}
          imageAlign="right"
          quote={quotes.walter}
        />
        <QuoteMosaicBlock
          title="Lead cross-team initiatives with confidence"
          content={
            <>
              Stay on top of ongoing initiatives, quickly spot at-risk work, and
              course correct before it’s too late.
              <br />
              <br />
              You can estimate the end date of your projects and easily track
              which people have contributed to different initiatives.
            </>
          }
          image={getImage(data.mosaicRight2)!}
          quote={quotes.dan}
        />
        <QuoteMosaicBlock
          title="Get practical feedback from your engineers"
          content={
            <>
              Complement system metrics with sentiment data from your software
              engineers and use those insights to improve developer experience.
              <br />
              <br />
              Surveys help you distinguish between isolated incidents and
              broader patterns, helping you make better decisions.
            </>
          }
          image={getImage(data.mosaicRight3)!}
          imageAlign="right"
          quote={quotes.alex4}
        />
        <LeadBlock
          heading="Improve the business impact&nbsp;of&nbsp;engineering"
          content={
            <>
              Get a clear picture of where teams are spending their time and
              strike the right balance between different types of engineering
              work.
            </>
          }
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.investmentbalance)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="christian" />
        <LeadBlock
          heading="Go beyond metrics with actionable tooling"
          content="Swarmia helps you build the feedback loops you need to get a little bit better every day."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Speed up your CI/CD pipelines"
              text="Improve the health and performance of your CI environment."
              image={getImage(data.cicdsmall)}
              href="/product/ci-cd-insights/"
              target="_self"
            />,
            <Blurb
              key="2"
              image={getImage(data.workingAgreement1)}
              title="Adopt working agreements"
              text="Work with your team to set relevant and achievable targets."
              href="/product/working-agreements/"
              target="_self"
            />,
            <Blurb
              key="3"
              image={getImage(data.workingAgreement2)}
              title="Automate feedback loops"
              text="Get real-time notifications in Slack to keep up with your agreements."
              href="/github-slack-integration/"
              target="_self"
            />,
          ]}
        />
        <QuoteBlock person="josep" />
        <ToolsBlock />
        <Box paddingBottom={responsiveScale(32)}>
          <CTABlock
            title={<>Start creating a better developer experience today</>}
          />
          <LeadBlock
            heading={<>Here’s how it works</>}
            content="Watch our 12-minute product overview to understand how Swarmia gives every level of the engineering organization the insights they need to ship better software faster."
          />
          <VideoBlock
            paddingTop={responsiveScale(64)}
            paddingBottom={responsiveScale(32)}
            videoId="ZHcQO20bL4w"
            thumbnail={getImage(data.videoThumbnail)!}
          />
        </Box>
      </GlobalStyleReset>
    </Layout>
  )
}

export default ExperiencePlatformPage
