/* eslint-disable no-irregular-whitespace */
import React from 'react'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Stack from '../primitives/Stack'

import AxiosHQ from '../../assets/images/customers/white-wall/AxiosHQ.svg'
import Choco from '../../assets/images/customers/white-wall/Choco.svg'
import Docker from '../../assets/images/customers/white-wall/docker.svg'
import Factorial from '../../assets/images/customers/white-wall/Factorial.svg'
import Factoryfix from '../../assets/images/customers/white-wall/Factoryfix.svg'
import Gorgias from '../../assets/images/customers/white-wall/Gorgias.svg'
import Metacore from '../../assets/images/customers/white-wall/metacore.svg'
import Hostaway from '../../assets/images/customers/white-wall/Hostaway.svg'
import Matillion from '../../assets/images/customers/white-wall/Matillion.svg'
import Miro from '../../assets/images/customers/white-wall/Miro.svg'
import Pleo from '../../assets/images/customers/white-wall/Pleo.svg'
import Prima from '../../assets/images/customers/white-wall/Prima.svg'
import Skutopia from '../../assets/images/customers/white-wall/Skutopia.svg'
import Smartly from '../../assets/images/customers/white-wall/Smartly.svg'
import Swappie from '../../assets/images/customers/white-wall/Swappie.svg'
import Tithely from '../../assets/images/customers/white-wall/Tithely.svg'
import Truelayer from '../../assets/images/customers/white-wall/Truelayer.svg'
import Webflow from '../../assets/images/customers/white-wall/Webflow.svg'
import Wetransfer from '../../assets/images/customers/white-wall/Wetransfer.svg'
import ProductsUp from '../../assets/images/customers/white-wall/ProductsUp.svg'
import Aiven from '../../assets/images/customers/white-wall/aiven.svg'
import Learnship from '../../assets/images/customers/white-wall/learnship.svg'
import Honeycomb from '../../assets/images/customers/white-wall/Honeycomb.svg'
import CDQ from '../../assets/images/customers/white-wall/cdq.svg'
import Trustpilot from '../../assets/images/customers/white-wall/trustpilot.svg'
import Button from '../system/Button'

const companies = [
  { name: 'Miro', image: Miro, link: '/story/miro/' },
  { name: 'Webflow', image: Webflow, link: 'https://webflow.com/' },
  { name: 'Pleo', image: Pleo, link: '/story/pleo/' },
  { name: 'Docker', image: Docker, link: 'https://www.docker.com/' },
  { name: 'Choco', image: Choco, link: '/story/choco/' },
  { name: 'Factorial', image: Factorial, link: '/story/factorial/' },
  { name: 'Aiven', image: Aiven, link: 'https://aiven.io/' },
  { name: 'Matillion', image: Matillion, link: 'https://www.matillion.com/' },
  { name: 'Truelayer', image: Truelayer, link: '/story/truelayer/' },
  { name: 'Gorgias', image: Gorgias, link: '/story/gorgias/' },
  { name: 'Trustpilot', image: Trustpilot, link: 'https://trustpilot.com/' },
  { name: 'Smartly', image: Smartly, link: 'https://www.smartly.io/' },
  {
    name: 'Metacore',
    image: Metacore,
    link: 'https://metacoregames.com/',
  },
  { name: 'Wetransfer', image: Wetransfer, link: 'https://wetransfer.com/' },
  { name: 'Honeycomb', image: Honeycomb, link: 'https://www.honeycomb.io/' },
  { name: 'Hostaway', image: Hostaway, link: '/story/hostaway/' },
  { name: 'AxiosHQ', image: AxiosHQ, link: 'https://www.axioshq.com/' },
  { name: 'Tithely', image: Tithely, link: 'https://get.tithe.ly/' },
  { name: 'Swappie', image: Swappie, link: 'https://swappie.com/' },
  { name: 'Prima', image: Prima, link: '/story/prima/' },
  { name: 'Skutopia', image: Skutopia, link: '/story/skutopia/' },
  { name: 'Factoryfix', image: Factoryfix, link: '/story/factoryfix/' },
  { name: 'Learnship', image: Learnship, link: 'https://www.learnship.com/' },
  {
    name: 'ProductsUp',
    image: ProductsUp,
    link: '/story/productsup/',
  },
  { name: 'CDQ', image: CDQ, link: 'https://www.cdq.com/' },
]

export function DarkLogoWallBlock() {
  return (
    <Box
      position="relative"
      backgroundColor="logoBlockDark"
      paddingY={{ xs: 48, sm: 92, md: 148 }}
    >
      <Block>
        <Box
          display="grid"
          gridGap={32}
          gridColumnGap={32}
          gridRowGap={64}
          gridTemplateColumns={{
            xs: 'repeat(2, minmax(0, auto))',
            md: 'repeat(4, minmax(0, auto))',
            lg: 'repeat(5, minmax(0, auto))',
          }}
          justifyContent="center"
          alignItems="center"
        >
          {companies.map(company => {
            const isCaseStudy = company.link.startsWith('/')
            return (
              <Button
                key={company.name}
                variant="plain"
                color="logoWallLink"
                hoverProps={{ color: 'logoWallLink' }}
                font="logoWallLink"
                textTransform="uppercase"
                href={company.link}
                target={isCaseStudy ? undefined : '_blank'}
              >
                <Stack
                  display="flex"
                  flex={1}
                  width={200}
                  maxWidth="100%"
                  alignItems="center"
                  justifyContent="center"
                  css="&:hover .text { opacity: 1 }"
                >
                  <Box.img
                    src={company.image}
                    alt={company.name}
                    maxWidth="100%"
                  />
                  <Box
                    className="text"
                    opacity={0}
                    transition="all 0.15s ease-out"
                  >
                    {isCaseStudy ? 'Read story' : 'Visit site'}
                  </Box>
                </Stack>
              </Button>
            )
          })}
        </Box>
      </Block>
    </Box>
  )
}
