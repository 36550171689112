import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/blog-meta.png'
import dot from '../assets/images/blog/dot.svg'
import star from '../assets/images/blog/star.svg'
import Layout from '../components/Layout'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import { SecondaryCTA } from '../components/SecondaryCTA'
import { SpaceScaleValue } from '../styles/stylePropTypes'
import theme from '../styles/theme'

const Blog = ({ data }) => {
  return (
    <Layout
      justifyCenter
      title="Blog"
      description="If you’re interested in building happy and high-performing software teams, you’ve come to the right place."
      metaImage={MetaImage}
      isNew
    >
      <Box paddingTop={48} css="background-color: #fafafa;">
        <Block maxWidth="mediumBlock">
          <Box
            display="flex"
            flexDirection="column"
            gap={32}
            alignItems="center"
          >
            <Box display="flex">
              <Box.img
                position="relative"
                left="40%"
                zIndex={0}
                css={`
                  position: relative;
                  left: 40%;
                  z-index: 0;
                `}
                src={dot}
                aria-hidden="true"
              />
              <Box.img
                marginTop={5 as SpaceScaleValue}
                zIndex={1}
                src={star}
                aria-hidden="true"
              />
            </Box>
            <Box.h1 font="h1" className="title">
              Swarmia blog
            </Box.h1>
            <Box.h6 font="blueh6" paddingBottom={16}>
              Latest articles
            </Box.h6>
            <Posts data={data} />
            <SecondaryCTA />
          </Box>
        </Block>
      </Box>
    </Layout>
  )
}

export default Blog

const Posts = ({ data }) => {
  const {
    allMdx: { edges },
  } = data
  return edges.map(
    ({
      node: {
        excerpt,
        frontmatter: { slug, title, author, date, meta, showCoverImage },
      },
    }) => {
      const cover = getImage(meta.image)
      return (
        <Box key={slug}>
          <article key={slug}>
            <Link to={slug}>
              <Box
                transition="transform 0.2s ease-in-out"
                paddingX={{ xs: 32, md: 64 }}
                paddingTop={{ xs: 24, md: 48 }}
                paddingBottom={32}
                borderRadius={12}
                css={`
                  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05),
                    0px 10px 38px rgba(23, 23, 26, 0.05);

                  &:hover {
                    transform: scale(1.02);

                    .read-story {
                      .text {
                        text-decoration-color: ${theme.colors.dataPink};
                      }
                      .arrow {
                        left: 0px;
                      }
                    }
                  }
                `}
              >
                <Box.h2 font="h2" paddingBottom={4}>
                  {title}
                </Box.h2>
                <Box color="black500">
                  {author.name} · {date}
                </Box>
                {cover && showCoverImage && (
                  <Box paddingTop={32}>
                    <Box
                      borderRadius={{ xs: 12, md: 24 }}
                      overflow="hidden"
                      isolation="isolate"
                    >
                      <GatsbyImage image={cover} alt={meta.imageAlt ?? ''} />
                    </Box>
                  </Box>
                )}
                <Box.p paddingTop={24}>{excerpt}</Box.p>
                <Box paddingTop={12} className="read-story">
                  <Box.span
                    className="text"
                    font="h5"
                    display="inline"
                    textDecoration="underline"
                    css={`
                      text-decoration-color: #f3b3e0;
                      text-underline-offset: 6px;
                    `}
                  >
                    Read now
                  </Box.span>{' '}
                  <Box.span
                    position="relative"
                    fontWeight={500}
                    css={`
                      transition: all 0.15s ease-out;
                      top: 1px;
                      left: -2px;
                    `}
                    className="arrow"
                  >
                    →
                  </Box.span>
                </Box>
              </Box>
            </Link>
          </article>
        </Box>
      )
    },
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/src/blog/" } }
    ) {
      ...BlogPostsFragment
    }
  }

  fragment BlogPostsFragment on MdxConnection {
    edges {
      node {
        excerpt(pruneLength: 192)
        frontmatter {
          date(formatString: "MMM D, YYYY")
          slug
          title
          showCoverImage
          author {
            name
            jobTitle
          }
          meta {
            image {
              childImageSharp {
                gatsbyImageData(width: 1000, quality: 90)
                original {
                  src
                  width
                  height
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`
